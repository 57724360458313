<template>
  <div>
    結帳完成頁面(成功/失敗)
  </div>
</template>

<script>
export default {};
</script>

<style></style>
